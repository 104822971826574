import { createApp } from "vue";
import App from "./App.vue";
import { setupRouter } from "./router";
import store from "./store";
import { setupI18n } from "./lang/i18n";
import lazyPlugin from "vue3-lazy";
import VueGtag from "vue-gtag-next";
import Clipboard from "./directives/clipboard";
import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";

// import en from "@/lang/lang_en";

import "@/style/main.scss";

const app = createApp(App);
const i18n = setupI18n({
  globalInjection: true,
  silentTranslationWarn: true,
  legacy: true,
  locale: "",
});
const router = setupRouter(i18n);

Sentry.init({
  app,
  dsn: "https://25f297857d1c4b62b1442f7c7cd4fe94@sentry-bgwpau.linkr.bio/7",
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracingOrigins: ["linkr.com", "dev.linkr.com", /^\//],
    }),
  ],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

app.use(store);
app.use(router);
app.use(i18n);

app.use(VueGtag, {
  property: {
    id: "G-EP5LSFL11G",

    params: {
      send_page_view: true,
    },
    linker: {
      domain: ["linkr.com", "linkr.bio"],
      accept_incoming: true,
    },
  },
});

app.use(lazyPlugin, {
  preLoad: 1.3,
  attempt: 2,
  loading: "https://cdn.static.linkr.bio/static/linkrcom/public/loading.svg",
  error: "https://cdn.static.linkr.bio/static/linkrcom/public/error.png",
});
app.directive("clipboard", Clipboard);

app.mount("#app");
